import { ITranslationStrings } from './interface';

const translations: ITranslationStrings = {
  //@ts-ignore
  translation: {
    poweredBy: 'מופעל על ידי',
    /* Sidebar translations */
    // Client
    orderMeal: 'הזמנת ארוחה',
    fastVoucher: 'שובר ארוחה',
    noFutureVoucher: 'אין הזמנה עתידית',
    reviewOrder: 'הוספת חוות דעת',
    noOrderToReview: 'אין הזמנה לדירוג',
    myMeals: 'ההזמנות שלי',
    choosingMealTime: 'בחירת מועד ארוחה',
    chooseDishForMeal: 'בחירת מנה מעבר הארוחה',
    chooseDishFor: 'בחירת מנה מעבר',
    chooseDish: 'בחירת מנה',
    changeDishFor: 'החלף מנה מעבר',
    changeDish: 'החלפת מנה',
    userProfile: 'פרופיל',
    error: 'שגיאה',
    logout: 'התנתקות',

    forgotPassword: 'שכחתי את הסיסמה', // Kitchen

    mealManagement: 'ניהול ארוחות',
    reservationManagement: 'ניהול הזמנות',
    dishManagement: 'ניהול מנות',
    tagManagement: 'ניהול תגיות',
    ordersHistory: 'היסטורית הזמנות',
    Settings: 'הגדרות',
    dishDelivery: 'חלוקת מנות',
    textWithEmoji: "התוכן שהוזן מכיל תווים שאינם נתמכים (אימוג'י)",
    registerSuccessfully: 'ההרשמה התבצעה בהצלחה',
    profileUpdated: 'פרטי המשתמש עודכנו בהצלחה',
    orderVoucher: 'שובר הזמנה',
    nextMeal: 'הארוחה הקרובה',
    nextMeals: 'הארוחות הבאות',
    noInfo: 'אין מידע',
    noRating: 'אין דירוג',
    mealRanOut: 'המוצר אזל',
    toOrder: 'להזמנה',
    lastOrder: 'הזמנה אחרונה',
    selectDishType: 'בחירת סוג מנה',
    selectMealType: 'בחירת סוג ארוחה',
    mealType: 'סוג ארוחה',
    kitchenName: 'שם מטבח',
    selectMealDate: 'בחירת יום',
    addReview: 'הוספת חוות דעת',
    noOrdersToDisplay: "'לא נמצאו הזמנו",
    lobby: 'לובי',
    hello: 'שלום',
    confirm: 'אישור',
    signup: 'הרשמה',
    resetPassword: 'איפוס סיסמה',
    orderSummaryTitle: 'סיכום הזמנה',
    loginTitle: 'כניסה',
    addReviewTitle: 'הוספת חוות דעת',
    backToDishSelection: 'חזרה לבחירת מנה',
    dishSelection: 'בחירת מנה',
    changeOrder: 'שינוי הזמנה',
    orderConfirmation: 'אישור הזמנה',
    noMeals: 'לא קיימת ארוחה מעבר התאריך וסוג הארוחה המבוקש',
    rateUsingStars: 'מהי שביעות רצונך מההזמנה',
    writeDishOpinion: 'חוות דעתך על ההזמנה (אופציונאלי)',
    sendReview: 'שליחת חוות דעת',
    additionalInfo: 'מידע נוסף',
    orderReceivedSuccessfully: 'הזמנתך התקבלה בהצלחה',
    viewVoucher: 'צפייה בשובר',
    cancelOrder: 'ביטול הזמנה',
    orderNotRealized: 'הזמנה לא מומשה',
    orderNotActive: 'הזמנה לא פעילה',
    dishIsOrdered: 'המנה שהוזמנה',
    chooseAnotherDish: 'נא לבחור מנה אחרת',
    backToMyMeals: 'חזרה אל הארוחות שלי',
    backToMealConstruction: 'חזרה אל הרכבת תפריט',
    backToDishManagement: 'חזרה לניהול מנות',
    backToMealManagement: 'חזרה לניהול ארוחות',
    realizedOrders: 'מומש',
    ordersFromInventory: 'הזמנות מתוך המלאי',
    realizedOrder: 'מומש',
    realizeOrder: 'מימוש הזמנה', // Form placeholders And Labels

    emailPH: 'אימייל',
    emailLoginPH: 'שם משתמש',
    namePH: 'שם',
    passwordPH: 'סיסמה',
    repeatPasswordPH: 'אימות הסיסמה',
    updatePassword: 'עדכון סיסמה',
    rolePH: 'תפקיד',
    kitchenPH: 'מטבח',
    firstNamePH: 'שם פרטי',
    lastNamePH: 'שם משפחה',
    phonePH: 'טלפון',
    cardIdPH: 'מספר כרטיס',
    employeeIdPH: 'מספר עובד',
    employeeIdOrEmail: 'מייל או מספר עובד',
    connectLabel: 'התחברות',
    submitLabel: 'שליחה',
    keepMeLoggedLabel: 'השאר אותי מחובר',
    iWantToReceiveViaMail: 'אני רוצה לקבל במייל אישורי הזמנות',
    saveInfo: 'שמירה',
    category: 'קטגוריה',
    name: 'שם',
    shortDescription: 'תיאור קצר',
    cancel: 'ביטול',
    raters: 'חוות דעת',
    loginWithOtp: 'כניסה עם קוד חד פעמי',
    labelSendOtp: 'איך ברצונך לקבל את הקוד',
    sendEmailOtp: 'שלחו לי קוד',
    labelEnterOtp: 'קוד',
    placeholderOtp: 'קוד שהתקבל',
    verify: 'אמת קוד',
    or: 'או',

    // Kitchen Management
    homeMenu: 'בית',
    controlPanel: 'לוח בקרה',
    addClientUser: 'הוספת משתמש',
    defineNewMeal: 'הגדרת ארוחה חדשה',
    mealReport: 'דוח ארוחה',
    nextMealToDefine: 'הארוחה הבאה שצריך להגדיר',
    addDish: 'הוספת מנה',
    editDish: 'עריכת מנה',
    edit: 'עריכה',
    manageDishes: 'ניהול מנות',

    showPreviousMeal: 'הצגתת ארוחות קודמות',
    showPreviousDates: 'הצגתת תאריכים קודמים',
    noFutureDates: 'אין ארוחות עתידיות',

    menuConstruction: 'הרכבת תפריט לארוחה',
    orders: 'הזמנות',
    dishesInMeal: 'מנות בארוחה',
    inventory: 'מלאי',
    delete: 'מחיקה',
    addImage: 'הוספת תמונה',
    date: 'תאריך',
    type: 'סוג',

    addDishToMeal: 'הוספת מנות לארוחה',
    includedInMeal: 'כלולה בארוחה',
    noDishes: 'אין מנות זמינות להצגתה',
    moreInfo: 'מידע נוסף',
    addMeal: 'הוספת ארוחה',

    open: 'פתיחה',
    hide: 'הסתרה',
    toOrders: 'להזמנות',
    dishes: 'מנות',

    // Control Panel
    previousMeal: 'הארוחה הקודמת',
    mealSegmentation: 'פילוח מנות',
    moreMeals: 'ארוחות נוספות',

    dishesRanOutOutOf: 'מנות אזלו מתוך',

    numOfReviews: 'מספר חוות דעת',
    averageScore: 'ציון ממוצע',

    dishesRatingInTheLast: 'דירוג המנות בטווח של',
    popularMealInTheLast: 'מנות פופולאריות בטווח של',
    days: 'ימים',

    noInventoryInMeal: 'אין מלאי לארוחה',

    userManagement: 'ניהול משתמשים',
    addUser: 'הוספת משתמש',
    enterUserDetails: 'הכנסת פרטי משתמש',

    noUsers: 'אין משתמשים קיימים להצגתה',
    systemUsers: 'משתמשים במערכת',

    // Validators
    invalidEmail: 'כתבות אימייל לא תקינה',
    invalidID: 'מספר תעודת זהות לא תקין',
    invalidPhoneNumber: 'מספר טלפון לא תקין',
    enterNameInHebrew: 'נא להזין שם בעברית',
    requiredField: 'שדה נדרש',
    passMinLength: 'מינימום אורך הסיסמה צריך להיות 8 תוים',
    passwordsDontMatch: 'הסיסמהות החדשות אינן תואמות',

    // Swal Alerts
    back: 'חזרה',
    removeDishTitle: 'מחיקת מנה',
    removeDishBtnLabel: 'מחיקת מנה',
    areYouSureYouWantToRemoveDish: "לחיצה על 'מחיקת מנה' תסיר את המנה",
    cancelOrderTitle: 'ביטול הזמנה',
    orderWasCanceled: 'ההזמנה בוטלה',
    cancelOrderBtnLabel: 'ביטול הזמנה',
    areYouSureYouWantToCancelOrder: "לחיצה על 'ביטול הזמנה' תסיר את ההזמנה",
    replaceOrderBtnLabel: 'החלפת מנה',
    areYouSureYouWantToReplaceOrder: "לחיצה על 'החלפת מנה' תחליף את ההזמנה",
    areYouSureYouWantToSelectAnotherDish:
      "לחיצה על 'בחירת מנה אחרת' תעביר למסך בחירת מנה",
    selectAnotherDishBtnLabel: 'בחירת מנה אחרת',
    orderExistsTitle: 'כבר קיימת הזמנה',
    enterEmailTitle: 'יש להזין אימייל',
    enterEmailText: 'מה האימייל מעברו יישלח לינק לאיפוס סיסמה',
    sendViaEmail: 'שליחה למייל',

    resetPasswordTitle: 'איפוס סיסמה',
    resetPasswordText: 'לינק איפוס סיסמה נשלח לאימייל שלך',
    passwordUpdatedTitle: 'הסיסמה עודכנה בהצלחה',
    passwordUpdatedText: 'כעת ניתן להתחבר למערכת בעזרת הסיסמה החדשה',
    passwordUpdatedConfirmButtonText: 'מעבר למסך כניסה',

    otpEmailSuccess:
      'אם כתובת הדוא"ל קיימת במערכת נשלחה לך הודעה עם קוד כניסה חד-פעמי',
    otpPhoneSuccess:
      'אם מספר הטלפון קיים במערכת נשלחה לך הודעה עם קוד כניסה חד-פעמי',
    otpAddAtleastOneField: 'יש למלא כתובת דוא"ל או מספר טלפון נייד',

    // New translations 11/4/21
    inventoryLessThanOrdered: 'לא ניתן להגדיר מלאי קטן מכמות ההזמנות בפועל',
    inventoryLessThanZero: 'לא ניתן להגדיר מלאי קטן מאפס',
    returnToMyMeals: 'חזרה אל הארוחות שלי',
    returnToDishSelection: 'חזרה לבחירת מנה',
    returnToChangeDish: 'חזרה להחלפת מנה',
    returnToDishManagement: 'חזרה לניהול מנות',
    returnToMealManagement: 'חזרה לניהול ארוחות',
    returnMenuConstruction: 'חזרה להרכבת תפריט',

    noMealsForThisDate: 'אין מנות זמינות בתאריך',

    // New translations 5/5/21
    imagesForIllustration: 'התמונות להמחשה בלבד',
    noFutureMeal: 'אין ארוחה עתידית',
    firstTimeHere: 'פעם ראשונה פה? להרשמה >>',
    loginDetails: 'פרטי התחברות',
    identifyByEmployeeId: 'זיהוי לפי מספר עובד',
    identifyByEmail: 'זיהוי לפי אימייל', // New translations 12/5/21

    thereIsAlreadyAnOrder: 'כבר קיימת הזמנה מעבר המנה הזאת',
    signupFormExplanation: 'ניהול המידע האישי שלך',
    noNextMeal: 'אין במערכת ארוחה קרובה',
    alreadyRealizedForDate: 'מומשה כבר הזמנה לארוחה זו',

    // New translations 17/6/21
    genericError: 'אופס, ארעה תקלה',
    cantDeleteMealWithOrders: 'לא ניתן למחוק ארוחה עם הזמנות',

    removeMealTitle: 'מחיקת ארוחה',
    removeMealBtnLabel: 'מחיקת ארוחה',
    areYouSureYouWantToRemoveMeal: "לחיצה על 'מחיקת ארוחה' תסיר את הארוחה של",

    loadingLabel: 'אוכלים חכם', // New translations 12/7/21

    enterInventoryFor: 'עדכון מלאי עבור', // New translations 21/7/21

    returnToMenuConstruction: 'חזרה לניהול תפריט',
    returnToMealReport: 'חזרה לדוח ארוחה',
    realized: 'מומשו',
    chefDish: 'מנת השף', // New translations 26/7/21

    addKitchen: 'הוספת מטבח',
    kitchensManagement: 'ניהול מטבחים',
    employeeManagement: 'ניהול עובדים',
    lock: 'חסום',
    unlock: 'ביטול חסימה',
    noKitchens: 'אין מטבחים זמינים',
    systemKitchens: 'מטבחים במערכת',
    close: 'סגור',
    enterKitchenDetails: 'הכנסת פרטי מטבח',
    active: 'פעיל',
    notActive: 'לא פעיל',
    statusPH: 'סטטוס',
    registrationDatePH: 'תאריך הרשמה', // New translations 15/8/21

    filterByStatus: 'סינון לפי סטטוס',
    statusesPH: 'סטטוס',
    blocked: 'חסום',
    kitchensPH: 'מטבחים', // New translations 2/9/21

    deleteUserTitle: 'מחיקת משתמש',
    deleteUserBtnLabel: 'מחיקת משתמש',
    areYouSureYouWantToDeleteUser: "לחיצה על 'מחיקת משתמש' תמחיקת את המשתמש",
    tempPasswordPH: 'סיסמה זמנית',
    setPassword: 'בחירת סיסמה',
    employeeWasAdded: 'משתמש נוסף בהצלחה', // New translations 29/9/21

    welcomeToWorkeat: 'ברוכים הבאים אל WorkEat',
    theWayTo: 'הדרך לאכול בעבודה',
    setPasswordDescription: `
                            <p style="text-align: right; width:30%">
                                שוב שלום,<br>
                                אנחנו שמחים שבאת.<br>
                                בכדי להתחיל יש לבחור סיסמה חדשה:
                            </p>
                    `,

    resetPasswordDescription: `
                            <p style="text-align: right; width:30%">
                            נא לבחור סיסמה חדשה
                            </p>
                    `, //new translatinos 15/10/21

    permissionsError: 'נותקת מהמערכת, נא להתחבר מחדש', // TODO - check for correctness

    paymentManagementButton: 'ניהול תשלומים',

    companyNumber: 'מספר חברה',
    personalHashKey: 'מפתח אישי',
    merchantId: 'זיהוי סוחר',

    'paymentOption-tenBis': '10bis',
    'paymentOption-cibus': 'סיבוס',
    'paymentOption-creditCard': 'כרטיס אשראי',
    'paymentOption-salary': 'שכר',
    paymentManagementFormExplanation:
      'אנא מלא את הפרטים הבאים בהתאם להעדפותיך ומלוח המחוונים של NayaX.',
    paymentPreferncesUpdated: 'הגדרות ניהול התשלומים עודכנו.',

    price: 'מחיר',
    orderPrice: 'מחיר',
    positiveNumber: 'חייב להיות מספר חיובי',
    needValidEmployeeIdOrEmail: 'חייב להיות מספר עובד חוקי או אימייל.',

    reservedByString: 'שמור על ידי',
    reservedByFirstName: 'שם פרטי',
    reservedByLastName: 'שם משפחה',

    reservationDate: 'תאריך הזמנה',
    reservationNumber: '#',

    reservationDetails: 'פרטים',
    reservationKitchen: 'מטבח',
    reservationMealType: 'סוג ארוחה',
    reservationMealDate: 'תאריך ארוחה',
    reservationDish: 'מנה',
    reservationPayment: 'תשלום',
    reservationChannel: 'מקור',
    cardLastDigits: 'ספרות אחרונות של כרטיס',

    addNewPaymentMethod: 'הוספת אמצעי תשלום חדש',
    successfullySavedPaymentMethod: 'אמצעי התשלום נשמר בהצלחה.',
    errorSavingPaymentMethod:
      'אירעה שגיאה בעת ניסיון לשמור את אמצעי התשלום. נסה שוב!',
    viewStoredPaymentMethods: 'הצגת אמצעי תשלום מאוחסנים',
    updateDefaultPaymentMethod:
      'עדכון את אמצעי התשלום המוגדר כברירת מחדל' /* Audit translate */,

    audit: 'לוגים',
    id: 'ID',
    fieldName: 'שם',
    eventType: 'סוג ארוע',
    prevValue: 'ערך קודם',
    currValue: 'ערך חדש',
    pageName: 'שם העמוד' /* ***** */,

    employeeName: 'שם',
    employeeNo: 'מזהה פנימי',
    cardNo: 'מספר כרטיס',
    status: 'סטטוס',
    tag: 'שם תג',
    addTag: 'הוספת תגית',
    returnToTagManagement: 'חזרה לניהול תגים',
    isActive: 'פעיל',
    isPublic: 'ציבורי',
    selectTags: 'בחירת תגים',
    registrationDate: 'נוצר ב',
    kitchenname: 'מטבחים',
    email: 'אימייל',
    action: 'פעולה',
    downloadCSV: 'הורדת קובץ ייצוא CSV',
    add: 'הוספה',
    pageSize: 'גודל עמוד',
    editbutton: 'עריכה',
    deletebutton: 'מחיקה',
    role: 'תפקיד',
    userName: 'שם',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    phone: 'טלפון',
    password: 'סיסמה חדשה',
    roles: 'תפקידים',
    update: 'עדכון',
    updateUser: 'עדכון משתמש',
    updateEmployee: 'עדכון עובד',
    addUsers: 'הוספת משתמש',
    addEmployee: 'הוספת עובד',
    cookieContent: 'אנחנו משתמשים ב-  Cookies בכדי לשפר את חווית המשתשמש שלך.',
    okay: 'בסדר',
    tagUpdatedSuccessfully: 'התג עודכן בהצלחה',
    acceptbtn: 'אישור',
    rejectbtn: 'דחייה',
    createddate: 'תאריך הפקה',
    companyName: 'שם החברה',
    company: 'שם החברה',
    totalInventory: 'מלאי',
    totalReservations: 'הזמנות',
    totalRealized: 'מומשו',
    dishName: 'מנה',
    dishesInOrder: 'מנה',
    tagsname: 'תגיות',
    tInventory: 'מלאי',
    reservations: 'הזמנות',
    tRealized: 'מומש',
    chef: 'מנת שף',
    printReport: 'הדפסה',
    mealtype: 'סוג ארוחה',

    security: 'אבטחה',
    securityFormTitle: 'אפשר גישת מנהל רק מהכתובות הבאות',
    securityFormBlacklistPlaceholder: 'רשימת כתובות',
    securitySubmitButton: 'שמירה',
    ipv4FormatIncorrect: 'כתובת לא תקינה',
    securityFormSuccess: 'ההגדרות נשמרו בהצלחה',
    confirmModalTitle: 'האם לבצע שינויים?', //19-12-2021
    fromDate: 'מתאריך',
    toDate: 'עד תאריך',
    orderId: 'מספר הזמנה',

    clearDish: 'ניקוי כל המנות',
    updateInventory: 'עדכון מלאי',
    copyMeal: 'העתקה מארוחה אחרת',
    clearText: 'האם למחוק את כל המנות?',

    selectSlot: 'בחירת זמן ארוחה',
    preferedTime: 'יש לבחור חלון זמן',
    breakfast: 'תזמון ארוחת הבוקר',
    lunch: 'תזמון ארוחת צהריים',
    dinner: 'תזמון ארוחת ערב',
    overtime: 'תזמון שעות נוספות',
    night: 'תזמון לילה',
    firstSeat: 'מושב ראשון',
    secondSeat: 'מושב שני',
    thirdSeat: 'מושב שלישי',
    dishSelections: 'בחירת המנה',

    addMealtype: 'סוגי ארוחות',
    mealTypeId: 'סוגי ארוחות',
    mealtypes: 'סוגי ארוחות',
    startTime: 'שעת התחלה',
    endTime: 'שעת סיום',
    mealtypeSlot: 'חלונות זמן להזמנה',
    maxReservations: 'מקסימום הזמנות במועד',
    selectDate: 'תאריך',
    selectMealtype: 'סוג ארוחה',
    voucherExpired: 'פג תוקף השובר',
    mealTimeManagement: 'ניהול סוגי ארוחות',
    maxReservationMessage: 'לא נותר עוד מקום במועד זה',
    ok: 'בסדר',
    extendalId: 'מזהה חיצוני',
    orderIndex: 'סדר',
    existTime: 'הזמן כבר מוגדר לארוחה הזו',
    deleteMealTypeSlotError: 'ניתן למחוק רק ארוחות ללא הזמנות',
    deletedSuccessfully: 'נמחקה בהצלחה',
    editSuccessfully: 'נערך בהצלחה',
    addedSuccessfully: 'נוסף בהצלחה',
    orderIndexerror: 'סדר צריך להיות ייחודי',
    orderZeroError: 'סדר לא יכול להיות אפס',
    noSlotsAvailable: 'לא הוגדרו חלונות זמן להושבה בארוחה זו',
    UpdateMaxReservations: 'עדכון כמות סועדים',
    realizedOrderText: 'ההזמנה מומשה' /*******logs  */,

    description: 'תיאור',
    time: 'זמן',
    controller: 'בקר',
    tableName: 'טבלה',
    updatedData: 'נתונים מעודכנים',

    welcomeText: 'ברוכים הבאים',
    mealAction: 'פעולות ארוחה',
    send: 'שלח',

    viewMore: 'הצגת עוד',
    allRatingDish: 'כל חוות הדעת למנה זו',
    reviews: 'חוות דעת',
    comments: 'הערות',
    addComment: 'הוספת תגובה',
    showdishes: 'הצגת מנות',

    privacyPolicy: 'מדיניות פרטיות',
    termsOfUse: 'מדיניות שימוש',
    logViewer: 'לוג מערכת',
    user: 'משתמש',
    rating: 'דירוג',
    averageMeal: 'ציון ממוצע של חוות דעת',
    popuplar: 'פופולרי',
    apply: 'החל',
    noAverageMealReview: 'אין ציון עדיין',
    noPopularDishes: 'אין מנות פופולריות',
    noFastVoucher:
      'ברגע שהארוחה הקרובה תחל נציג כאן קישור לשובר ההזמנה - הארוחה התחילה ולא מוצג קישור לשובר? הגיע הזמן להזמין',
    day: 'יום',
    noofDishes: 'מספר מנות',
    addPreviousDish: 'הוספה למנה הקודמת',
    availableExtras: 'תוספות זמינות לארוחה שלך',
    finalizeOrder: 'סיים הזמנה',
    skip: 'דילוג',
    extrameal: 'תוספות בארוחה',
    addExtras: 'בחירת תוספות',
    paymentConfirm: 'בעת מימוש ההזמנה יחויב כרטיס אשראי המסתיים בספרות:',
    paymentAlert:
      'אין אמצעי תשלום פעיל בחשבונך - בכדי להשלים את ההזמנה יש להוסיף אמצעי תשלום ולבצע את ההזמנה מחדש. לחץ על כפתור "הוספת אמצעי תשלום" בכדי למעבר לעמוד ניהול התשלומים',
    paymentInfo: 'פרטי התשלום',
    addMethod: 'הוספת אמצעי תשלום',
    extras: 'תוספות',
    none: 'אין',
    slot: 'חלון זמן',
    reservationExport: 'הזמנות יוצאו ב-',
    fromtime: 'משעה',
    totime: 'עד שעה',
    applyfilter: 'החלת סינון',
    CopyFromPreviousDish: 'העתקה מארוחה אחרת',
    CopyMeal: 'העתקה',
    'Credit Card': 'כרטיס אשראי', //sms message//

    yourOrder: 'הזמנתך ב',
    of: 'של',
    in: 'ביום ',
    smsMessage: 'התקבלה, לחיצה על הקישור תציג את השובר למימוש בחדר האוכל',
    RedirectingYouToLoginPage: 'מעבר לעמוד התחברות',
    cancelOrderText: 'לחץ על "אישור" בכדי לבטל את הזמנתך של',
    cancelOrderTextin: 'ב',
    changeOrderText: 'לחץ על "אישור" בכדי להחליף את הזמנתך של',
    changeOrderTextto: 'אל',
    passwordSecurity:
      'אורך הסיסמה חייב להיות לפחות 8 אותיות, לפחות אות אחת גדולה, אות קטנה אחת וסמל אחד.',
    updatedMessage: 'עודכן בהצלחה',
    addedmessage: 'נוסף בהצלחה',
    deleteMessage: 'נמחק בהצלחה',
    currentPasswordError: 'הכנסת סיסמה נוכחית',
    voucherRealized: 'השובר כבר מומש.',
    voucherNotDisplay: 'לא ניתן להציג את השובר',
    search: 'חיפוש',
    chooseYourDish: 'בחירת מנה',
    profile: 'פרופיל',
    scanVoucher: 'יש לסרוק את ה-QR הזה כדי לממש את ההזמנה שלך.',
    groupsManagement: 'ניהול קבוצות',
    contactPersonName: 'שם איש קשר',
    contactEmail: 'אימייל ליצירת קשר',
    contactPhone: 'טלפון ליצירת קשר',
    addContractor: 'הוספת קבוצות',
    updateContractor: 'עדכון קבוצות',
    paymentDetails: 'פרטי תשלום',
    amountPaid: 'סכום ששולם',
    timeOfPayment: 'זמן התשלום',
    transactionId: 'מזהה עסקה',
    futureMeals: 'ניהול ארוחות נוכחי',
    pastMeals: 'ארוחות קודמות',
    reports: 'דיווחים',
    changePassword: 'שינוי את הסיסמה',
    oldPassword: 'סיסמה ישינוי',
    newPassword: 'סיסמה חדשה',
    paymentManagement: 'ניהול תשלומים',
    selectPaymentOption: 'בחירת אפשרות תשלום',
    selectCurrency: 'בחירת מטבע',
    realizedStatus: 'סטטוס מימוש',
    contractorName: 'שם קבוצות',
    selectContractor: 'בחירת קבוצות',
    alertForContractor: 'לא ניתן להזמין את הארוחה מאחר ולא משויך לך מטבח.',
    realizedDate: 'תאריך מימוש',
    realizedMessage: 'ההזמנה כבר מומשה בתאריך',
    at: 'ב-',
    webapp: 'Webapp',
    dispenser: 'מחלק',
    resetPasswordExpired:
      ' הלינק לשחזרה הסיסמה אינו בתוקף או שכבר נוצל - בכדי לשחזר סיסמה יש ללחוץ שוב על הקישור של שחזור סיסמה',
    character: 'התו',
    isNotVaild: 'אינו תקף עם מדיניות הסיסמהות -',
    inCorrectPassword: 'סיסמה לא נכונה',
    contractorEmployeeManagement: 'עובדי קבוצות',
    addContractorEmployee: 'הוספת עובד קבוצות',
    updateContractorEmployee: 'עדכון עובד קבוצות',
    contractorId: 'מזהה קבוצות',
    userWalletManagement: 'ניהול ארנק משתמש',
    creditDate: 'תאריך אשראי',
    creditAmount: 'סכום אשראי',
    debitAmount: 'סכום החיוב',
    debitDate: 'תאריך חיוב',
    remainingAmount: 'מאזן',
    wallet: 'ארנק',
    chooseCurrency: 'אנא בחירת מטבע',
    cancellationIsAvaiableUntill: 'ביטול הזמנה מתאפשר עד השעה',
    ofTheSame: 'ביום הארוחה, הזמנה שלא בוטלה או לא מומשה - תחויב',
    cancellationTime: 'זמן ביטול',
    multipleDish: 'מנה מרובה',
    creditPoints: 'נקודות',
    orderMealHeading: 'הזמינו ארוחה',
    continue: 'המשך',
    my: 'שלי',
    order: 'הזמנה',
    finish: 'סיום',
    chooseADishToContinue: 'בחירת מנה להמשך',
    totalAmount: 'סכום סופי',
    viewOrder: 'צפה בהזמנה',
    orderDate: 'תאריך הזמנה',
    paymentMethod: 'אמצעי תשלום',
    totalData: 'סך הנתונים',
    dish: 'מאכל',
    product: 'מוצר',
    extra: 'תוספת',
    quantity: 'כמות',
    amount: 'כמות',
    mealDate: 'תאריך ארוחה',
    myOrders: 'ההזמנות שלי',
    voucher: 'שובר',
    selectMealTypeSecond: 'בחירת סוג ארוחה',
    menu: 'תפריט',
    Category: 'קטגוריה',
    accountSettings: 'הגדרות חשבון',
    account: 'חשבון',
    password1: 'סיסמה',
    payment: 'תשלום',
    save: 'שמירה',
    passwordSettings: 'הגדרות סיסמה',
    currentPassword: 'סיסמה נוכחית',
    newPassword1: 'סיסמה חדשה',
    confirmPassword: 'אישור סיסמה',
    enterCurrentPassword: 'הכנסת סיסמה נוכחית',
    enterNewPassword: 'הכנסת סיסמה חדשה',
    confirmNewPassword: 'אישור סיסמה חדשה',
    terminalNumber: 'מספר מסוף',
    russian: 'רוסית',
    english: 'אנגלית',
    hebrew: 'עברית',
    editKitchen: 'עריכת מטבח',
    placeOrderMessage: 'ההזמנה התקבלה בהצלחה',
    orderDetails: 'פרטי הזמנה',
    confirmOrder: 'האם אתה בטוח שאתה רוצה לאשר את ההזמנה של',
    alertOrderMsg: 'כבר הזמנת!.',
    loginText: 'התחברות',
    loginFormText: 'בואו נתחיל',
    loginDesc:
      'במידה ואין לך פרטי התחברות יש לפנות למחלקת משאבי אנוש או מערכות מידע',
    remember: 'זכור אותי',
    addToOrder: 'הוספה להזמנה',
    runOut: 'נגמר',
    userStatus: 'סטטוס משתמש',
    UnavailableMealTypes: 'כרגע אין סוגי ארוחות זמינים.',
    addMealType: 'הוספת סוג ארוחהs',
    image: 'תמונה',
    noExtrasDisplay: 'אין תוספות להצגתה',
    walletManagement: 'ניהול ארנק',
    mealName: 'שם הארוחה',
    credit: 'אשראי',
    walletDetails: 'פרטי הארנק',
    insufficientWalletAlert: 'היתרה שלך בארנק אינה מספיקה לביצוע הזמנה זו.',
    copyUrl: 'העתקה כתובת URL',
    walletBalance: 'יתרת ארנק',
    BreakfastRealized: 'ארוחת הבוקר התממשה',
    BreakfastReservation: 'הזמנת ארוחת בוקר',
    EveningRealized: 'ערב התממש',
    EveningReservation: 'הזמנת ערב',
    LunchRealized: 'ארוחת צהריים התממשה',
    LunchReservation: 'הזמנת ארוחת צהריים',
    NightExtraRealized: 'תוספת לילה התממשה',
    NightExtraReservation: 'הזמנה נוספת ללילה',
    NightRealized: 'לילה התממש',
    NightReservation: 'הזמנת לילה',
    OverTimeRealized: 'עם הזמן התממש',
    OverTimeReservation: 'הזמנה לאורך זמן',
    cardId: 'מזהה כרטיס',
    employeeId: 'כרטיס עובד',
    totalReservation: 'הזמנה מלאה',
    user_type: 'סוג משתמש',
    mealTypeName: 'סוג ארוחה',
    totalOfMeals: 'סך הכל ארוחות',
    ofreservations: 'הזמנות',
    perceReservedInventory: '% הזמנות/מלאי',
    ofRealised: 'מתוך המימושים',
    ofRealisedInventory: '% מימושים/מלאי',
    detailedReservations: 'הזמנות מפורטות',
    sortBy: 'מיין לפי',
    dishNameAscending: 'שם המנה (א-ת)',
    dishNameDescending: 'שם המנה (ת-א)',
    priceAscending: 'מחיר (הגבוה לנמוך)',
    priceDescending: 'מחיר (הנמוך לגבוה)',
    ratingAscending: 'דירוג (גבוה לנמוך)',
    ratingDescending: 'דירוג (נמוך לגבוה)',
    contractors: 'קבוצות',
    wallets: 'ארנקים',
    employee: 'עובד',
    kitchens: 'מטבחים',
    kitchen: 'מטבח',
    kitchenId: 'מטבח',
    totalPrice: 'מחיר סופי',
    review: 'סקירה',
    alreadyAdded: 'כבר נוסף',
    allMeal: 'כל הארוחות',
    reviewScore: 'סקירת ציון',
    SecondaryText: 'טקסט משני',
    GotoControlPanel: 'מעבר ללוח הבקרה',
    Averagedailyreviewsscore: 'ציון סקירות יומי ממוצע',
    home: 'בית',
    placeHolderByDishSearch: 'חפש מנות לפי שם, קטגוריה, מחיר או דירוג',
    added: 'נוסף',
    reviewManagement: 'ניהול סקירה',
    updateDish: 'עדכון מנה',
    openSelectMenu: 'פתח את תפריט הבחירה הזה',
    dishAddedMessage: 'המנה נוספה בהצלחה',
    dishUpdatedMessage: 'המנה עודכנה בהצלחה',
    selectUserType: 'בחירת סוג משתמש',
    admin: 'מנהל מערכת',
    siteManager: 'מנהל אתר',
    kitchenManager: 'מנהל מטבח',
    kitchenWorker: 'עובד מטבח',
    contractorEmployee: 'עובד קבוצות',
    contractorAdmin: 'מנהל קבוצות',
    client: 'לקוח',
    userType: 'סוג משתמש',
    reviewTrend: 'סקירת מגמה',
    termsAndCondition: 'תנאים והגבלות',
    inv: 'מלאי',
    ord: 'אורד',
    rel: 'Rel', // New translations 08/02/22
    forgotSubHeading:
      'מלא את כתובת הדואר האלקטרוני שלך למטה ואנו נשלח לך אימייל עם הנחיות נוספות.',
    poweredByWorkEat: 'מופעל על ידי WorkEat',
    searchEmployeePH: 'חפש עובד לפי שם, דוא"ל או תאריך',
    setting: 'הגדרה',
    searchForTagManagement: 'חפש תגיות לפי שם',
    updateTag: 'עדכון תג',
    kitchenDetail: 'פרטי המטבח',
    logViewerDetails: 'פרטי מציג יומן',
    previousMealDetail: 'פירוט הארוחה הקודמת',
    ordered: 'הוזמן',
    mealsDetailsTitle: 'פרטי ארוחות',
    searchUserPH: 'חפש משתמשים לפי שם, דוא"ל או תאריך',
    realizeDate: 'תאריך עדכון',
    totalsByUsers: 'הזמנות לפי משתמשים',
    mealTypeInReservation: 'הזמנות לפי ארוחות',
    freeTextSearch: 'חיפוש חופשי',
    pending: 'ממתין לאישור',
    success: 'הצלחה',
    canceled: 'מבוטל',
    contractor: 'קבוצות',
    reportType: 'סוג דוח',
    filter: 'סינון',
    invoice: 'חשבונית',
    isInvoice: 'חשבונית',
    guestUsers: 'משתמשים אורחים',
    updateGuestUser: 'עדכון משתמש אורח',
    addGuestUser: 'הוספת משתמש אורח',
    guestUserManagement: 'ניהול משתמש אורחים',
    startDate: 'תאריך התחלה',
    endDate: 'תאריך סיום',
    hireFrom: 'תאריך התחלה',
    hireTill: 'תאריך סיום',
    past: 'עבר',
    next: 'הבא',
    meal: 'ארוחה',
    noOrder: 'אין ארוחה להזמנה',
    dateDay: 'תאריך ויום',
    selectDishes: 'בחירת מנות',
    approve: 'אישור',
    nextLobbyMeals: 'הארוחות הקרובות',
    pastLobbyMeals: 'ארוחות שהסתיימו',
    noMeal: 'אין ארוחה להעתיק אז אנא בחירת ארוחה אחרת.',
    orderRealized: 'מומש',
    ofRealizedReserved: '% מומש/שמורה',
    today: 'היום',
    walletAmount: 'כמות',
    walletAmountWord: 'סכום',
    UpdateWalletAMount: 'עדכון את כמות הארנק',
    updatedOn: 'עודכן ב- ',
    realizeError: 'הארוחה עדיין לא התחילה - האם לבצע מימוש בכל מקרה או לא?',
    notRealized: 'לא מומש',
    realizedMethod: 'שיטת מימוש',
    realizedByUserName: 'מימוש על ידי שם משתמש',
    realizedByDispenserName: 'מימוש על ידי שם המתקן',
    realizedByDispenserNameReport: 'מימוש על ידי שם המתקן',
    resend: 'לשלוח שוב',
    totalOfInventory: 'מלאי',
    collectPoints: 'צבירה',

    confirmCollectPointMessage2: 'נקודות עבור',
    alertNoMealsAbleMessage: 'אין ארוחות זמינות!.',

    successMealTypePointMessage: 'אספת בהצלחה נקודה',
    errorMealTypePointMessage: 'כבר אספת נקודה מעבר הארוחות האלה.',
    pointMsg: 'האם לבקש לצבור',
    collectPointsMess: 'נקודות עבור ארוחת',
    pointMsgEnd:
      'התקבלה בהצלחה, הנקודות יועברו לארנקך תוך 24 שעות לכל היותר בהתאם למדיניות החברה',
    approvedPointMsg: 'מאושר',
    donePointMsg: 'עודכן',
    is_include_sallary_file: 'כלול קובץ שכר',
    is_calculate_Price: 'חשב את מחיר המנה',
    updateMealType: 'עדכון את סוג הארוחה',
    revertCollectPointMessage: 'האם לבטל את הבקשה לצבור',
    successReturnMealTypePointMessage: 'הנקודות הוחזרו בהצלחה',
    pendingCollectedPoint: 'נקודת אשראי',
    textManually: 'באופן ידני',
    dateError: 'התאריך הסופי חייב להיות שווה או גדול מתאריך התחלה',
    pay: 'תשלום',
    magneticStripe: 'פס מגנטי',
    cibus: 'סיבוס',
    paid: 'שולם',
    paymentSuccessMessage: 'התשלום עבר בהצלחה',
    generateSalaryFile: 'צור קובץ משכורת',
    paymentFailureMessage: 'מצטער! כשל בתשלום',
    paymentOrderId: 'מזהה הזמנת תשלום',
    notPaid: 'לא שולם',
    paymentStatus: 'מצב תשלום',
    UserName: 'שם משתמש',
    isSalary: 'שכר',
    isSalaryReport: 'שכר',
    isfile: 'שם קובץ',
    isCreatedAt: 'נוצר ב',
    isUpdatedAt: 'עודכן ב-',
    isDownload: 'הורדת',
    salaryFileReport: 'דו"ח שכר',
    isEntries: 'ערכים',
    getReservation: 'קבל הזמנות',
    dropImage: 'לחץ כאן או גרור תמונה',
    remove: 'הסרה',
    cibusPayment: 'תשלום באמצעות סיבוס',
    cibusPaymentAlert: 'האם לשלם כעת מעבר הזמנה זו?',
    deleteMeal: 'מחיקת את הארוחה',
    companyId: 'מספר חברה',
    companyCard: 'כרטיס חברה',
    referenceId: 'מזהה מסעדה',
    posId: 'מזהה עמדה',
    applicationId: 'מזהה אפליקציה',
    authorization: 'הרשאה',
    showReservation: 'הצגת הזמנה',
    resendMessage: 'דואר לאיפוס סיסמה נשלח לאימייל של המשתמש.',
    cibusError: 'שגיאת סיבוס',
    codeNumber: 'קוד לא.',
    reservation: 'הזמנה',
    manual: 'מדריך ל',
    qr: 'QR',
    card: 'כרטיס',
    isAproved: 'אושר',
    guestLink: 'קישור אורח',
    generatedLink: 'קישור אורח',
    enterQuantityFor: 'Enter quantity for',
    forceSetPasswordDescription: ` <p style="text-align: left; width:30%">תוקף סיסמתך פג - יש לבחור סיסמה חדשה</p>`,
    yes: 'כן',
    no: 'לא',
    cancelledBy: 'ההזמנה בוטלה על ידי',
    cancelledOrderTime: 'זמן ביטול הזמנה',
    selectReservationStatus: 'בחירת סטטוס הזמנה',
    reservationStatus: 'מצב הזמנה',
    totalCreditPointRequest: 'סך  נקודות',
    pendingCreditPointRequest: ' נקודות לא מאושרות',
    approvedCreditPointReqquest: ' נקודות מאושרות',
    creditPointPercentage: '% נקודות',
    paymentType: 'סוג תשלום',
    paymentOptionId: 'סוג תשלום',
    selectPaymentType: 'בחירת סוג תשלום',
    selectReservationType: 'בחירת סוג הזמנה',
    displayName: 'הצגת שם',
    deviceType: 'סוג מכשיר',
    location: 'מקום',
    createdAt: 'נוצר ב',
    updatedAt: 'עודכן ב-',
    addKitchenDevice: 'הוספת מכשיר מטבח',
    kitchenDevices: 'מכשירי מטבח',
    updateKitchenDevice: 'עדכון את מכשיר המטבח',
    token: 'טוקן',
    kitchenDevice: 'מכשיר מטבח',
    vatFreePrice: 'מחיר ללא מע"מ',
    addOrderUrl: 'הוספת כתובת אתר להזמנה',
    paymentUrl: 'כתובת אתר לתשלום',
    webAuth: 'WebAuth',
    oAuth: 'OAuth',
    host: 'כתובת אתר מארח',
    languages: 'שפות',
    shortCode: 'קוד קצר',
    isRTL: 'האם RTL',
    languageStatus: 'סטטוס שפה',
    addLanguage: 'הוספת שפה',
    updateLanguage: 'עדכון את השפה',
    language: 'שפה',
    notEdit: 'לא ניתן לעריכה',
    copyToken: 'אסימון העתקהה',
    downloadFile:
      'הקובץ שביקשת יורד למחשב ברגע שיהיה מוכן. נא לא לסגור את הטאב עד שהקובץ יתקבל',
    cancellOrder: 'ההזמנה בוטלה בהצלחה',

    userId: `מס' משתמש`,
    createdDate: 'תאריך רישום',

    Refusal_by_credit_company: 'Refusal by credit company (hebrew)',

    enterNonEmptyPassword: 'הכנסת סיסמה נוכחית',
    FileInpEmpty: 'לא התקבל קובץ',
    TitleEmpty: ' ההאדר לא יכול להיות ריק',
    removeForeignContractor:
      'This group cannot be removed as it relates to another group administrator',
    attendance: 'נוכחות',
    attendanceTitle: 'נוכחות',
    entry: 'כניסה',
    exit: 'יציאה',
    all: 'כל',
    deleteAttachedFile: 'האם למחוק את הקובץ המצורף?',
    attachedFile: 'קובץ מצורף',
    multipleReservations: 'ריבוי הזמנות',
    fileTitle: 'כותרת הקובץ',
    titleTooLarge: 'עד 20 תווים',
    fileDeleted: 'הקובץ נמחק',
    orderedAndRealized: 'בתאריך הנבחירת ישינוי כבר ההזמנה שמומשה',
    orderedMeal: 'בתאריך הנבחירת יש כבר הזמנה קיימת - האם לערוך הזמנה זו?',
    backOrEdit: 'האם להמשיך בבחירת מוצרים או לסיים את הרכישה?',
    checkout: 'סיום',
    selectKitchen: 'בחירת מטבח',
    deleted: 'נמחק',
    created: 'נוצר',
    selectUserStatus: 'בחירת סטטוס משתמש',
    allContractor: 'בחירת קבוצות',
    paymentNotMatch:
      'בחשבון זה אין פרטי תשלום - יש להשלים את התשלום ולבצע מימוש מחדש',
    generateSalaryText: 'יש לבחור את התאריכים מעברם יופק קובץ השכר:',
    dateRange: 'טווח תאריכים',
    salaryGenerated: 'קובץ משכורות נוצר בהצלחה',
    reservationByUser: 'הזמנות לפי משתמש',
    reservationByMeal: 'הזמנות לפי ארוחות',
    copyMeals: 'העתקת ארוחות',
    copyMealFromTitle: 'יש לבחור את התאריכים מהם נעתיק את הארוחות',
    copyMealToTitle: 'לאלו תאריכים להעתיק את הארוחות שנבחירתו בתאריכים',
    copyMealFromText: 'האם להעתיק את הארוחות בתאריכים',
    to: 'אל',
    copy: 'העתקה',
    copyMealSuccess: 'הארוחות הועתקו בהצלחה',
    copyMealStarted:
      'תהליך ההעתקה החל ויכול להימשך עד מספר דקות - ניתן להמשיך לעבוד במערכת בינתיים',
    dateRangeNotMatch:
      'בתווך התאריכים שנבחירת, מספר ימי עבודה אינו תואם את מספר הימים מהשלב הקודם.',
    dateRangesIntersect: 'תווכי תאריכים חופפים (חלקית) זה לזה.',
    reservationType: 'סוג הזמנה',
    copyPastDateTitle: 'העתקה לזמן עבר',
    copyPastText: 'האם ברצונך להעתיק את המנות לתקופה שכבר עברה?',
    deleteMealTypeConfirmation: 'האם ברצונך למחוק את הארוחה?',
    selectMealTypes: 'בחירת סוגי ארוחות',
    allMealTypes: 'כל סוגי ארוחות',
    specificMealTypes: 'בחירת סוגים ספציפיים של ארוחות',
    mealsNotSelect: 'ארוחות לא נבחירתו',
    noPastDates: 'אין היסטוריית ארוחות',
    totalByMeals: 'סה"כ ארוחות',
    totalByUsers: 'סה"כ משתמשים',
    totalByReservations: 'סה"כ הזמנות',
    relatedMealTypes: 'ביחס לסוגי ארוחות',
    dishCategory: 'סוג מנה',
    system: 'מערכת',
    generatedBy: 'נוצר ע"י',
    activate: 'הפוך לפעיל',
    emplyeeID: 'מספר עובד',
    cardID: 'מספר כרטיס',
    activatedSuccessfully: 'נהפך לפעיל בהצלחה',
    activateUserConf: 'את/ה בטוח/ה שברצונך להפוך משתמש זה לפעיל?',
    deleteUserConf: 'את/ה בטוח/ה שברצונך למחוק משתמש זה?',
    dateRangeTooBig: 'טווח תאריכים גדול מדי',

    cancelPointSuccess1: 'בקשתך לביטול הבקשה לצבירת',
    cancelPointSuccess2: 'נקודות מארוחת',
    cancelPointSuccess3: 'התקבלה בהצלחה',

    fieldIsRequired: 'זהו שדה חובה',

    factory: 'שם מפעל',
    factoryName: 'מטבחי המפעל',
    factoryKitchens: 'מטבחי מפעל',
    addFactory: 'הוסף מפעל',
    updateFactory: 'עדכון המפעל',
    deleteFactory: 'מחק מפעל',
    factoryManagement: 'ניהול מפעל',
    deleteFactoryConfMess: 'האם ברצונך למחוק מפעל זה?',
    factoryDetails: 'פרטי המפעל',
    cibusRedirectUrl: 'לינק חזרה של סיבוס',
    mainInfo: 'מידע ראשי',
    endLessStart: 'תאריך הסיום אינו יכול להיות נמוך מתאריך ההתחלה',
    total: 'סך',
    withoutKitchens: 'נראה שאינך מקושר לאף מטבח, יש לפנות לתמיכה',
    mealStatus: 'ארוחות',
    previousMealsStatus: 'ארוחות שהסתיימו',
    futureMealsStatus: 'ארוחות עתידיות',
    allMealsStatus: 'כל הארוחות',
    visitorCountry: 'מדינה',
    visitPurpose: 'סיבת הביקור',
    guestId: 'מזהה אורח',
    event: 'ארוע',
    meeting: 'פגישה',
    conference: 'כנס',
    technician: 'טכנאי',

    isMultiOrders: 'אפשרות להזמנה מרובת מנות',
    userCreateSuccessOne: 'המשתמש',
    userCreateSuccessTwo: 'נוצר בהצלחה',
    userUpdateSuccessTwo: 'עודכן בהצלחה',
    showUserCard: 'הצגת כרטיס משתמש',
    createAnotherUser: 'יצירת משתמש נוסף',

    inviteUser: 'הזמנת משתמש',
    updateAndApprove: ' עדכון ואישור',
    updateInvite: 'עדכון הזמנה',
    createInvite: 'יצירת הזמנה',
    reject: 'סירוב',
    guestUserInvitation: 'הזמנת אורח',
    inviteManagement: 'ניהול הזמנות',
    invitedBy: 'מזמין',
    createdBy: 'יוצר',
    invitedDate: 'תאריך הזמנה',
    approvedBy: 'מאשר',
    approvedAt: 'תאריך אישור',
    rejectedBy: 'סירוב',
    rejectedAt: 'תאריך סירוב',
    invitationStatus: 'סטטוס',
    guestInvitationStatusId: 'סטטוס',
    inviteDetails: 'פרטי הזמנה',
    selectInviteStatus: 'בחירת סטטוס',
    invitedMessage: 'ההזמנה נוצרה בהצלחה',
    updateAndInvite: 'עודכן ואושר בהצלחה',
    approveSuccess: 'אושר בהצלחה',
    rejectSuccess: 'סורב בהצלחה',
    invitationId: 'מספר הזמנה',
    unauthorized: 'לא מאושר',
    tokenExpired: 'הטוקן שלך פג תוקף - נא לטעון מחדש בכדי לרענן אותו.',
    viewer: 'צופה',
    copied: 'הועתק',
    confirmCollectPointMessage1: 'בקשתך לצבירת',
    approveMessConfirm: 'האם לאשר את בקשת האירוח?',
    rejectMessConfirm: 'האם לבטל את בקשת האירוח?',

    mealDetails: 'פרטי ארוחה',
    tomorrow: 'מחר',
    period: 'תקופה',
    forAllTime: 'כל התקופה',
    allStatuses: 'כל הסטטוסים',
    notOrdered: 'לא הוזמן',
    generalInfo: 'מידע כללי',
    filters: 'פילטרים',
    noAvailableMeals: 'אין ארוחות זמינות עבור שילוב הפילטרים שנבחר',
    dishQuantity: 'כמות מנות',
    availableMealsToOrder: 'ארוחות זמינות להזמנה',
    availableMealsToOrderShort: 'ארוחות אפשריות להזמנה',
    'dish-es': 'מנות',
    iWantToReceivePushNotifications: 'רוצה לקבל התראות פוש',
    title: ' כותרת',
    notifications: 'התראות',
    message: 'הודעה',
    param: 'פרמטרים',
    notificationsDetails: 'פרטי ההתראה',
    users: 'משתמשים',
    connectteam: 'קונקטים',
    readStatus: 'סטטוס',
    read: 'נקרא',
    unread: 'לא נקרא',
    readAll: 'סימון הכל כנקרא',
    reset: 'אתחול',
    numberSymbol: '#',
    invitationInfo: 'פרטי הזמנה',
    invitation: 'ההזמנה',
    alreadyApproved: 'אושרה כבר',
    alreadyRejected: 'נדחתה כבר',
    deleteKitchenConfMess: 'האם למחוק את המטבח?',
    deleteKitchenSuccess: 'המטבח נמחק בהצלחה',
    updateKitchenSuccess: 'המטבח עודכן בהצלחה',
    addKitchenSuccess: 'המטבח נוסף בהצלחה',
    res: 'הזמנה',
    '7days': '7 ימים',
    '6days': '6 ימים',
    '5days': '5 ימים',
    weekView: 'תצוגה שבועית',
    dishDescription: 'תיאור המנה',
    factories: 'מפעל',
    dayOfWeek: 'יום',
    dishParam: 'נתוני המנה',
    userWithoutWallet: 'נראה שיש בעיה עם הארנק שלך, בקשתך לא התקבלה',
    contactYourAdmin: 'יש לפנות לאדמין לעזרה',
    dishWeekView: 'מנה',

    contractorsIds: 'קבוצות',
    kitchensIds: 'מטבחים',
    roleId: 'תפקיד',
    userStatusId: 'סטטוס משתמש',

    oldValue: 'ערך ישן',
    newValue: 'ערך חדש',
    tagsHistory: 'היסטורית תגיות',
    updated: 'עודכן',
    history: 'היסטוריה',
    namehe: 'שם עברית',
    descriptionhe: 'תיאור עברית',
    nameen: 'שם אנגלית',
    descriptionen: 'תיאור אנגלית',
    nameru: 'שם רוסית',
    descriptionru: 'תיאור רוסית',
    emptyHistoryMess: 'אין מידע',
    tags: 'תגיות',
    orderWasPaid: 'התשלום התקבל בהצלחה',
    redirectToCibusSite: 'הפניה לאתר סיבוס להמשך התהליך',
    payByOneTimeCode: 'תשלום בעזרת קוד חד פעמי',
    enterOneTimeCode: 'נא להזין את הקוד החד פעמי',
    cancelledDate: 'תאריך ביטול',
    realizedUserName: 'מומש על ידי',
    orderStatusId: 'סטטוס',
    enterValidOneTimeCode: 'יש להזין קוד חד פעמי',
    deal_id: 'מזהה עסקה',
    kitchenDeviceId: 'מזהה מכשיר',

    mealHistory: 'היסטוריית ארוחה',
    isChefMealDish: 'מנת שף',
    dishId: 'שם מנה',
    mealDishStatusId: 'סטטוס מנה',
    chargeTerminalId: 'שינוי קוד טרמינל',
    separateFiles: 'קבצים נפרדים',
    reportsHistory: 'היסטורית דוחות',
    transactionHistory: 'היסטורית תנועות',
    forReports: 'עבור דוחות',
    downloadInvoice: 'הורדת מסמך מס',
    linkToInvoice: 'קישור למסמך מס',
    pleaseSelectDate: 'בחר תאריך',
    personalQRCode: 'קוד QR אישי',
    missingDish: 'אין מנות בהזמנה',

    fillRequiredFields: 'נא להשלים את השדות הנחוצים',
    userIdCibus: 'User ID(in Cibus)(he)',
    changePaymentStatus: 'שינוי סטטוס תשלום',
    changePaymentStatusMess: 'האם לשנות את הסטטוס של התשלום:',
    fromPendingToPaid: 'ממתין >>> שולם',
    fromPaidToCancel: 'שולם >>> מבוטל',
    howOrderWasPaid: 'כיצד ההזמנה שולמה',
    orderPaidBySite: 'ההזמנה שולמה דרך מערכת סיבוס',
    orderPaidByOtherMethods: 'ההזמנה שולמה בדרך אחרת',
    paymentDetailsWasUpdated: 'פרטי התשלום עודכנו בהצלחה',
    somethingWentWrong: 'ארעה שגיאה, נא לנסות שוב בהמשך',
    editPaymentDetails: 'עריכת פרטי תשלום',
    paymentOrderMessage: 'הערה לפרטי התשלום',

    notes: 'הערות',
    promote: 'לקדם',
    pleaseUpdateMealParam: 'נא לעדכן פרמטרי הארוחות',
    updateMealParam: 'עדכן פרמטרי הארוחות',
    updateAdditionalParam: 'עדכן פרמטרי הארוחות',
    titlehe: 'כותרת',
    titleen: 'Title',
    titleru: 'Заголовок',
    descriptionHE: 'תיאור',
    descriptionEN: 'Description',
    descriptionRU: 'Описание',
    notesen: 'Notes',
    noteshe: 'הערות',
    notesru: 'Записи',
    linkToPaymentReceipt: 'אישור רכישה',
    linkToCancellingReceipt: 'אישור ביטול',
  },
};

export default translations;
