import AppConfig from '../../constants/Config';
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import dates from '../../services/dates';
import { useTranslation } from 'react-i18next';
import { getAssetUrl } from '../../services/aws';
import {
  setLayoutOptions,
  showAlert,
  useFetchingLoader,
  useLoader,
  getReceipts,
} from '../../store/app/actions';
import { StaticStars } from '../../components/RatingStars';
import enums from '../../services/enums';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import WEIcon from '../../components/WE-IconButton';
import WEBackButton from '../../components/WE-BackButton';
import Layout from '../../components/Layout';
import { Box, Link } from '@material-ui/core';
// Img
import arrow from '../../assets/icons/eva_arrow-back-fill.svg';
import { Button } from 'react-bootstrap';
import { getPaymentPreferences } from '../../store/kitchens/actions';

// @ts-ignore
const OrderVoucher = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  receipts = [],
  getReceipts = () => {},
}) => {
  const [details, setDetails] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
    setLayoutOptions({ title: t('orderVoucher') });
  }, []);
  // setLayoutOptions({ title: t('orderVoucher') });
  const { orderId, authoTokenURL } = useParams();
  const MealTypesMap_Id = MealTypes.reduce((acc, dish) => {
    return { ...acc, [dish.id]: dish.display };
  }, {});
  useEffect(() => {
    const fetchData1 = async () => {
      getReceipts('orderId', orderId);
    };
    if (orderId) {
      fetchData1();
    }
  }, [orderId]);
  useEffect(() => {
    const fetchData1 = async () => {
      const order = await useFetchingLoader(async () => {
        if (authoTokenURL) {
          return http.get(`orders/anonymous/voucher/${authoTokenURL}`);
        } else {
          return http.get(`orders/${orderId}`);
        }
      });
      if (order?.mealDish?.length > 1) {
        const paymentdata = await getPaymentPreferences(
          order?.mealDish[0]?.meal?.mealType?.kitchenId,
          ''
        );
        setDetails({
          orderHash: order?.hash,
          orderId: order?.id,
          mealExtraName: order?.extras?.text,
          mealExtraPrice: order?.extras?.price,
          date: order?.mealDish?.[0]?.meal.date,
          mealType: order?.mealDish?.[0]?.meal.mealType.id,
          price: order?.orderPrice,
          authToken: order?.authToken,
          from: order?.mealSlot?.mealTypesSlot.from,
          to: order?.mealSlot?.mealTypesSlot.to,
          qrImage: order?.qrImage,
          orderStatus: order?.orderStatusId,
          realizeDate: order?.realizeDate,
          source: order?.platformId,
          mealDish: order?.mealDish,
          mealTitle: order?.mealTitle,
          mealDesc: order?.mealDesc,
          mealNotes: order?.mealNotes,
          userName: order?.user?.firstName + ' ' + order?.user?.lastName,
          creditPoints: order?.mealDish[0]?.meal?.mealType?.creditPoints,
          paymentOption: paymentdata?.selectedPaymentOptions,
        });
      } else {
        const { meal, dish } = order?.mealDish?.[0];

        const paymentdata = await getPaymentPreferences(
          meal?.mealType?.kitchenId,
          ''
        );
        setDetails({
          orderHash: order?.hash,
          orderId: order?.id,
          dishId: dish?.id,
          dishName: dish?.name,
          dishPrice: dish?.price,
          mealExtraName: order?.extras?.text,
          mealExtraPrice: order?.extras?.price,
          image: dish?.image,
          rating: dish?.rating,
          numOfRaters: dish?.numOfRaters,
          date: meal?.date,
          mealDish: order?.mealDish,
          mealType: meal?.mealType.id,
          description: dish?.description,
          price: order?.orderPrice,
          authToken: order?.authToken,
          from: order?.mealSlot?.mealTypesSlot.from,
          to: order?.mealSlot?.mealTypesSlot.to,
          qrImage: order?.qrImage,
          orderStatus: order?.orderStatusId,
          realizeDate: order?.realizeDate,
          source: order?.platformId,
          mealTitle: order?.mealTitle,
          mealDesc: order?.mealDesc,
          mealNotes: order?.mealNotes,
          userName: order?.user?.firstName + ' ' + order?.user?.lastName,
          creditPoints: meal?.mealType?.creditPoints,
          paymentOption: paymentdata?.selectedPaymentOptions,
        });
      }
    };
    fetchData1();
  }, [kitchen]);
  // let url = window.location.href;
  // let get = url.split('=');
  // let getAuthToken = get[1];

  return (
    <Layout>
      {/* <div className="order-voucher web-voucher voucher-page"> */}
      <div className="voucher-page">
        <div className="voucher-heading-section">
          <Button
            className="back-section"
            onClick={() => {
              backLink ? history.push(backLink) : history.goBack();
            }}
          >
            <img src={arrow} alt="icon" />
            <span>{t('back')}</span>
          </Button>
          <h2>
            {t('order')} #{details?.orderId}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <span>
                {dates.format(details?.date)} |{' '}
                {MealTypesMap_Id[details?.mealType]}
              </span>
              {details?.mealTitle && details?.mealDesc ? (
                <span>
                  {details?.mealTitle} | {details?.mealDesc}
                </span>
              ) : (
                <span> {details?.mealTitle ?? details?.mealDesc}</span>
              )}
            </Box>
          </h2>
        </div>
        {authoTokenURL && details ? (
          <>
            {/* {/ <WEBackButton text={backText} redirectHistory={backLink} /> /} */}
            {details.orderStatus === 2 ? (
              <div className="voucher-section">
                {details?.userName &&
                  !details?.userName.includes('undefined') && (
                    <h4 className="userName desktopSection">
                      {details?.userName}
                    </h4>
                  )}
                <span className="voucher-realized">
                  {t('realizedMessage')}{' '}
                  {dates.formatVoucher(details.realizeDate)}
                </span>
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    {details?.userName &&
                      !details?.userName.includes('undefined') && (
                        <h4 className="userName mobSection">
                          {details.userName}
                        </h4>
                      )}
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 6 ||
                            user?.roleId == 7 ||
                            user?.roleId == 8 ? (
                              ''
                            ) : (
                              <h5>{t('price')}</h5>
                            )}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 6 ||
                              user?.roleId == 7 ||
                              user?.roleId == 8 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                        ? ''
                                        : '₪' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 6 ||
                          user?.roleId == 7 ||
                          user?.roleId == 8 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* Order Table Ends */}
                      <Box
                        sx={{
                          fontSize: '16px',
                          color: '#6d7287',
                          textAlign: 'left',
                        }}
                      >
                        {details?.mealNotes}
                      </Box>
                      {user?.roleId == 8 ||
                      user?.roleId == 7 ||
                      user?.roleId == 6 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {receipts.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          textAlign: i18n.language === 'he' ? 'right' : 'left',
                        }}
                      >
                        {receipts.find((el) => el.receiptType === 'payment') ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToPaymentReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2758}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                        {receipts.find(
                          (el) => el.receiptType === 'cancelTransaction'
                        ) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToCancellingReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2759}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="voucher-section">
                {details?.userName &&
                  !details?.userName.includes('undefined') && (
                    <h4 className="userName desktopSection">
                      {details.userName}
                    </h4>
                  )}
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    {details?.userName &&
                      !details?.userName.includes('undefined') && (
                        <h4 className="userName mobSection">
                          {details.userName}
                        </h4>
                      )}
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 7 ? '' : <h5>{t('price')}</h5>}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 7 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                        ? ''
                                        : '₪' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 7 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <Box
                        sx={{
                          fontSize: '16px',
                          color: '#6d7287',
                          textAlign: 'left',
                        }}
                      >
                        {details?.mealNotes}
                      </Box>
                      {/* Order Table Ends */}
                      {user?.roleId == 7 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {receipts.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          textAlign: i18n.language === 'he' ? 'right' : 'left',
                        }}
                      >
                        {receipts.find((el) => el.receiptType === 'payment') ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToPaymentReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2758}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                        {receipts.find(
                          (el) => el.receiptType === 'cancelTransaction'
                        ) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToCancellingReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2759}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : details ? (
          <>
            {/* {/ <WEBackButton text={backText} redirectHistory={backLink} /> /} */}
            {details?.orderStatus === 2 ? (
              <div className="voucher-section">
                {details?.userName &&
                  !details?.userName.includes('undefined') && (
                    <h4 className="userName desktopSection">
                      {details.userName}
                    </h4>
                  )}
                <span className="voucher-realized">
                  {t('realizedMessage')}{' '}
                  {dates.formatVoucher(details.realizeDate)}
                </span>
                <div className="order-container">
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    {details?.userName &&
                      !details?.userName.includes('undefined') && (
                        <h4 className="userName mobSection">
                          {details.userName}
                        </h4>
                      )}
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 7 ? '' : <h5>{t('price')}</h5>}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => {
                                      return (
                                        <span>
                                          {el.quantity}
                                          <br />
                                        </span>
                                      );
                                    })
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 7 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? ''
                                              : '₪' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                        ? ''
                                        : '₪' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section">
                          <h5>{t('extra')}</h5>
                          <p>
                            <span> {details?.mealExtraName}</span>
                          </p>
                          {user?.roleId == 7 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <Box
                        sx={{
                          fontSize: '16px',
                          color: '#6d7287',
                          textAlign: 'left',
                        }}
                      >
                        {details?.mealNotes}
                      </Box>
                      {/* Order Table Ends */}
                      {user?.roleId == 7 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p>
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {receipts.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          textAlign: i18n.language === 'he' ? 'right' : 'left',
                        }}
                      >
                        {receipts.find((el) => el.receiptType === 'payment') ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToPaymentReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2758}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                        {receipts.find(
                          (el) => el.receiptType === 'cancelTransaction'
                        ) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToCancellingReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2759}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              // <div className="green-border shadow-card">
              <div className="voucher-section">
                {details?.userName &&
                  !details?.userName.includes('undefined') && (
                    <h4 className="userName desktopSection">
                      {details.userName}
                    </h4>
                  )}
                <div className="order-container">
                  {/* <DishImage fileName={details?.image} size={{ height: '' }} /> */}
                  <div className="qr-code">
                    <img
                      src={`data:image;base64,${details?.qrImage}`}
                      alt="QR_CODE"
                    />
                  </div>
                  <div className="voucher-section-right">
                    {details?.userName &&
                      !details?.userName.includes('undefined') && (
                        <h4 className="userName mobSection">
                          {details.userName}
                        </h4>
                      )}
                    <div className="order-details">
                      {/* Order Table Starts */}
                      <div class="my-orders-table">
                        <div class="my-orders-table-head">
                          <div class="table-text-section">
                            <h5>{t('dish')}</h5>
                            <h5>{t('quantity')}</h5>
                            {user?.roleId == 7 ? '' : <h5>{t('price')}</h5>}
                          </div>
                        </div>
                        <div class="my-orders-table-body">
                          <div class="my-orders-table-item">
                            <div class="table-text-section">
                              <p>
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>{el.dish.name}</span>
                                    ))
                                  ) : (
                                    <span> {details.dishName}</span>
                                  )}
                                </div>
                              </p>
                              <p>
                                {' '}
                                <div className="text-section-dishes">
                                  {details?.mealDish ? (
                                    details?.mealDish?.map((el, i) => (
                                      <span>
                                        {el.quantity}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span> {1}</span>
                                  )}
                                </div>
                              </p>
                              {user?.roleId == 7 ? (
                                ''
                              ) : (
                                <p>
                                  {' '}
                                  <div className="text-section-dishes">
                                    {details?.mealDish
                                      ? details?.mealDish?.map((el, i) => (
                                          <span>
                                            {details.paymentOption == 5
                                              ? el.dish.price * el.quantity
                                              : '₪' +
                                                el.dish.price * el.quantity}
                                          </span>
                                        ))
                                      : details.paymentOption == 5
                                        ? details.dishPrice
                                        : '₪' + details.dishPrice}
                                  </div>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {details?.mealExtraName ? (
                        <div className="total-price-section extra-div">
                          <h5>
                            {t('extra')}{' '}
                            <p>
                              <span> {details?.mealExtraName}</span>
                            </p>
                          </h5>

                          {user?.roleId == 7 ? (
                            ''
                          ) : (
                            <p>
                              {' '}
                              <span>
                                {' '}
                                {details.paymentOption == 5
                                  ? ''
                                  : '₪' + details?.mealExtraPrice}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <Box
                        sx={{
                          fontSize: '16px',
                          color: '#6d7287',
                          textAlign: 'left',
                        }}
                      >
                        {details?.mealNotes}
                      </Box>
                      {/* Order Table Ends */}
                      {user?.roleId == 7 ? (
                        ''
                      ) : (
                        <div className="total-price-section">
                          <h5>{t('totalAmount')}</h5>
                          <p className="remove-gap">
                            <span>{details.paymentOption == 5 ? '' : '₪'}</span>
                            <span>{details.price}</span>
                          </p>
                        </div>
                      )}
                    </div>
                    {receipts.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                          textAlign: i18n.language === 'he' ? 'right' : 'left',
                        }}
                      >
                        {receipts.find((el) => el.receiptType === 'payment') ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToPaymentReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'payment'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2758}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                        {receipts.find(
                          (el) => el.receiptType === 'cancelTransaction'
                        ) ? (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '5px',
                              color: '#2b3147',
                              fontWeight: '600',
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {`${t('linkToCancellingReceipt')}:`}
                            <Link
                              href={
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.url ??
                                receipts.find(
                                  (el) => el.receiptType === 'cancelTransaction'
                                )?.tempUrl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: '#14cd31',
                                cursor: 'pointer',
                                textDecoration: 'none',
                              }}
                              underline="none"
                            >
                              {2759}
                            </Link>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="green-border shadow-card">
            <div className="scan-voucher">
              {' '}
              <p>{t('scanVoucher')}</p>
              <iframe
                src={`${AppConfig.server.url}/orders/voucher/${orderId}`}
                sandbox=""
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  receipts: state.app.receipts,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getReceipts,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderVoucher);
