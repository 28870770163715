import React, { useEffect, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';
import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../../screens/client/DishImage';
import { useHistory, useLocation, useParams } from 'react-router';
import enums from '../../services/enums';
import { sleep } from '../../services/utils';
import dates from '../../services/dates';
import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import Chip from '@material-ui/core/Chip';
import BackLink from '../../components/BackLink';
import add from '../../assets/images/add.svg';
import searchimg from '../../assets/images/Search.svg';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import next from '../../assets/images/next.png';
import ViewMealDishDetail from './ViewMealDishDetail';
import cancel from '../../assets/images/close.svg';
import searchIcon from '../../assets/icons/search.png';
import Pagination from 'react-bootstrap/Pagination';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const AddDishToMeal = ({
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  user,
  kitchen,
  MealStatuses,
  DishStatuses,
  MealDishStatuses,
  MealTypes,
  DishCategories,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dishes, setDishes] = useState(null);
  const { date, mealTypeId } = useParams();
  const [tableColumn, setTableColumn] = useState();
  const [gridApi, setGridApi] = useState();
  const [dishesToDisplay, setDishesToDisplay] = useState(null);
  const [columnApi, setColumnApi] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();
  const location = useLocation();

  const [newPageSizeDishes, setPageSizeDishes] = useState(25); // Records per page
  const [totalDisheRecords, setTotalDisheRecords] = useState(0); // Total records
  const [pageCountsDishes, setpageCountsDishes] = useState(1); // Total pages count
  const [currentPageDishes, setCurrentPageDishes] = useState(1); // Current page
  const [searchKeyword, setSearchKeywordDishes] = useState(''); // Search text
  const [dishCategory, setDishCaregory] = useState(0);
  const [mealDishesIds, setMealDishesIds] = useState([]);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [dataForAutoComplete, setDataForAutocomplete] = useState([]);
  const [additionalMealParam, setAdditionalMealParam] = useState({
    dishId: 0,
    title: '',
    description: '',
    notes: '',
    promote: 0,
  });
  const { backText, backLink } = location.state ?? {};
  const lang = localStorage.getItem('language');
  useEffect(() => {
    const fetchData1 = async () => {
      const filterPayload = {
        kitchenId: kitchen.id,
        dishStatusId: DishStatusMap.active,
      };
      const allDishes = await useFetchingLoader(
        async () =>
          await http.get(`dishes/getAllForAutocomplete`, filterPayload)
      );
      setDataForAutocomplete(allDishes.data);
    };
    fetchData1();
  }, []);

  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});
  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type.display };
  }, {});
  const fetchData1 = async () => {
    setLayoutOptions({
      title: `${t('addDishToMeal')}`,
      showDatePicker: true,
      isDatePickerClickable: false,
    });
    if (!kitchen) return;
    const filterPayload = {
      kitchenId: kitchen.id,
      dishStatusId: DishStatusMap.active,
      page: currentPageDishes,
      limit: newPageSizeDishes,
      dishCategoryId: dishCategory == 0 ? undefined : dishCategory,
      findStr: searchKeyword === '' ? undefined : searchKeyword,
      orderField:
        'field' in columnAndOrder
          ? columnAndOrder?.field
          : 'dish|name|translation.text',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'asc',
    };
    Object.keys(filterPayload).forEach((key) => {
      if (filterPayload[key] === undefined) {
        delete filterPayload[key];
      }
    });
    const allDishes = await useFetchingLoader(
      async () => await http.get(`dishes`, filterPayload)
    );
    const { data: mealDishes } = await useFetchingLoader(
      async () =>
        await http.get(`mealsDishes`, {
          filter: {
            kitchenId: kitchen.id,
            date: new Date(date),
            mealTypeId,
            mealDishStatusId: [
              MealDishStatusMap.active,
              MealDishStatusMap.blocked,
            ],
            dishStatusId: DishStatusMap.active,
          },
        })
    );
    setTotalDisheRecords(allDishes.total);
    setpageCountsDishes(Math.ceil(allDishes.total / newPageSizeDishes));
    const mealDishesIds = mealDishes.map((md) => md.dish.id);
    const data = allDishes.data.map((dish) => ({
      ...dish,
      isIncluded: mealDishesIds.includes(dish.id),
    }));
    setDishes(data);
    let serverDishes = data.map((u, i) => ({
      dishId: u.id,
      dishName: u.name,
      dishImage: u?.thumbnail ? u?.thumbnail : u?.image,
      dishPrice: u.price,
      dishRating: u.rating,
      numberOfRaters: u.numOfRaters,
      dishCategory: enums.display('DishCategory', u.dishCategoryId),
      dishTag: u.tags,
      dishDescription: u.description,
      isIncluded: u.isIncluded,
    }));
    setDishesToDisplay(serverDishes);
  };
  useEffect(() => {
    fetchData1();
  }, [kitchen, currentPageDishes, dishCategory, columnAndOrder, searchKeyword]);

  const [search, setSearch] = useState('');
  // const handleSearchChange = (value) => setSearch(value);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };
  const handleAddDish = async (dishId) => {
    let requestValues = {
      dishId,
      date,
      mealTypeId: Number.parseInt(mealTypeId),
      kitchenId: kitchen.id,
      title: '',
      description: '',
      notes: '',
      promote: 0,
    };
    try {
      await useFetchingLoader(
        async () => await http.post(`mealsDishes/create`, requestValues)
      );
      setDishesToDisplay((dishesToDisplay) =>
        dishesToDisplay.map((dish) =>
          dish.dishId === dishId ? { ...dish, isIncluded: true } : dish
        )
      );
      setViewDetails(false);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };
  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 639) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 640) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);
  const handleReset = () => {
    setSearchKeywordDishes('');
    setColumnAndOrder({});
    setDishCaregory(0);
    setCurrentPageDishes(1);
    updateSuggestions('', true);
  };
  const columnDefs = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      autoHeight: true,
      wrapText: true,
      flex: 3,
    },
    {
      field: 'dish|description|translation.text',
      headerName: t('description'),
      sortable: true,
      comparator: () => {},
      flex: 5,
      cellRenderer: 'DishDescriptionNew',
      cellStyle: (params) => {
        return { margin: 'auto' };
      },
    },
    {
      field: 'dishCategoryId',
      headerName: t('category'),
      suppressMovable: true,
      sortable: true,
      comparator: () => {},
      flex: 1,
      cellRenderer: (params) => {
        return params.data.dishCategory ? params.data.dishCategory : '--';
      },
    },
    {
      field: 'price',
      headerName: t('price'),
      suppressMovable: true,
      width: 100,
      cellRenderer: (params) => {
        return params.data.dishPrice;
      },
    },
    {
      field: 'numOfRaters',
      headerName: t('rating'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'RatingStar',
      width: 150,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      width: 100,
    },
  ];

  /**** Tab view column */
  const columnDefsTab = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      flex: 3,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'dish|description|translation.text',
      headerName: t('description'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      flex: 5,
      cellRenderer: 'DishDescriptionNew',
    },
    {
      field: 'dishCategoryId',
      headerName: t('category'),
      suppressMovable: true,
      sortable: true,
      comparator: () => {},
      flex: 2,
      cellRenderer: (params) => {
        return `${params.data.dishCategory}`
          ? `${params.data.dishCategory}`
          : '--';
      },
    },
    {
      field: 'price',
      headerName: t('price'),
      suppressMovable: true,
      flex: 1,
      cellRenderer: (params) => {
        return params.data.dishPrice;
      },
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      // width: 100,
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      width: 100,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      flex: 2,
      autoHeight: true,
      wrapText: true,
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      flex: 1,
    },
    {
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  function updateSuggestions(query, isEmpty = false) {
    const suggestions = document.getElementById('suggestions');
    suggestions.innerHTML = '';
    if (!isEmpty && query) {
      const filteredSuggestions = dataForAutoComplete.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(query.toLowerCase())
      );
      filteredSuggestions.forEach((suggestion) => {
        const li = document.createElement('li');
        li.textContent = suggestion.name;
        li.onclick = () => {
          setSearchKeywordDishes(suggestion.name);
        };
        suggestions.appendChild(li);
      });
    }
  }

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();

    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  return (
    <div className={'dish-management-page global-data-table'}>
      <div className="container">
        <div className="top-header-section">
          <div className="search-section add-dish-to-meal-section">
            <BackLink
              redirectHistory={backLink}
              text={backText}
              additionalWrapperClassname={'back-link-additional'}
            />
            <div className="dish-management-search-block gap-4">
              <div className="from-date">
                <select
                  name="meal_type"
                  value={dishCategory}
                  onChange={(e) => {
                    setDishCaregory(e.target.value);
                    setCurrentPageDishes(1);
                  }}
                >
                  <option value={0}>{t('dishCategory')}</option>
                  {DishCategories.map((el, i) => (
                    <option value={el.id} key={i}>
                      {el.display}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex-add-dish">
                <div className="autocomplete">
                  <div className="search-input-section ml-0">
                    <input
                      className={`input add-dish-to-meal-search`}
                      type={'text'}
                      value={searchKeyword}
                      autocomplete="on"
                      onBlur={(e) =>
                        setTimeout(() => updateSuggestions('', true), 150)
                      }
                      onInput={(e) => {
                        updateSuggestions(e.target.value);
                        setSearchKeywordDishes(e.target.value);
                      }}
                      placeholder={`${t('search')}`}
                    />
                    <img src={searchimg} alt="search" />
                  </div>
                  <ul id="suggestions"></ul>
                </div>
                <p
                  onClick={() => {
                    handleReset();
                  }}
                  className="search-input-section-reset"
                >
                  {t('reset')}
                </p>
              </div>
            </div>
            {/**/}
          </div>
        </div>
        <>
          <AgGridReact
            className="ag-theme-alpine"
            rowData={dishesToDisplay}
            columnDefs={tableColumn}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            onSortChanged={() => printSortStateToConsole()}
            frameworkComponents={{
              NameCellRender: (e) => <NameCellRender e={e} />,
              EditDeleteCellRender: (e) => (
                <EditDeleteCellRender
                  e={e}
                  handleAddDish={() => handleAddDish(e?.data?.dishId)}
                  t={t}
                />
              ),
              DishDescriptionNew: (e) => <DishDescriptionNew e={e} />,
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
              RatingStar: (e) => <RatingStar e={e} />,
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
            }}
            debug={false}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            overlayLoadingTemplate={'<p></p>'}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeDishes * (currentPageDishes - 1) + 1} to{' '}
                {newPageSizeDishes * currentPageDishes} of {totalDisheRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsDishes <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageDishes(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsDishes <= 1 ? true : false}
                onClick={() => {
                  currentPageDishes > 1
                    ? setCurrentPageDishes(currentPageDishes - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageDishes} of{' '}
                {Math.ceil(totalDisheRecords / newPageSizeDishes)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsDishes <= 1 ? true : false}
                onClick={() => {
                  currentPageDishes <
                  Math.ceil(totalDisheRecords / newPageSizeDishes)
                    ? setCurrentPageDishes(currentPageDishes + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsDishes <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageDishes(
                    Math.ceil(totalDisheRecords / newPageSizeDishes)
                  );
                }}
              />
            </Pagination>
          </div>
        </>
      </div>
      <ViewMealDishDetail
        open={viewDetails}
        details={details}
        handleClose={handleViewMore}
        handleAddDish={() => handleAddDish(details?.dishId)}
      />
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <section className={'image'}>
      <DishImage fileName={e.data.dishImage} size={{ height: 100 }} />
      <div className="dish-name-with-tag">
        <p>{e.data.dishName}</p>
        {e.data.dishTag.map((tag) => (
          <Chip label={tag.displayName} size="small" />
        ))}
      </div>
    </section>
  );
};
const DishDescriptionNew = ({ e }) => {
  return (
    <section className={'dish-description-one'}>
      <p className="one-line-desc">{e.data.dishDescription}</p>
    </section>
  );
};
const EditDeleteCellRender = ({ e, handleAddDish, t = { t } }) => {
  return (
    <section>
      <div className={'actions'}>
        <div className={'include-add'}>
          {e.data.isIncluded ? (
            <p>{t('added')}</p>
          ) : (
            <img
              src={add}
              alt="add"
              onClick={() => handleAddDish(e.data.dishId)}
            ></img>
          )}
        </div>
      </div>
    </section>
  );
};

const RatingStar = ({ e }) => {
  return (
    <section className={'section2'}>
      <div className={'rating'}>
        <StaticStars
          rating={e.data.dishRating || -1}
          numOfRaters={e.data.numberOfRaters}
        />
      </div>
    </section>
  );
};
const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

// const Dish = ({ dishData, DishCategories, cb = () => {} }) => {
//   const { t } = useTranslation();
//   const DishCategoriesMap = DishCategories.reduce((acc, cat) => {
//     return { ...acc, [cat.id]: cat.display };
//   }, {});

//   return (
//     <div className={'dish dish-content'}>
//       <section className="dish-image">
//         <DishImage fileName={dishData.image} size={{ height: 100 }} />
//         <div className="dish-name-with-tag">
//           <p>{dishData.name}</p>
//           {dishData.tags.map((tag) => (
//             <Chip label={tag.displayName} size="small" />
//           ))}
//         </div>
//       </section>

//       <section className="dish-description">
//         <p>{dishData.description}</p>
//       </section>

//       <section>
//         <p className={'type'}>{DishCategoriesMap[dishData.dishCategoryId]}</p>
//       </section>
//       <section className="dish-price">
//         <p className={'type'}>₪ {dishData.price}</p>
//       </section>
//       <section className={'section2'}>
//         <div className={'rating'}>
//           <StaticStars
//             rating={dishData.rating || -1}
//             numOfRaters={dishData.numOfRaters}
//           />
//         </div>
//       </section>
//       <section>
//         <div className={'include-add'}>
//           {dishData.isIncluded ? (
//             <p>Added</p>
//           ) : (
//             // <WEIcon
//             //   icon={'plus'}
//             //   text={t('addDish')}
//             //   backgroundColor={'green'}
//             //   iconSize={'small'}
//             //   additionalWrapperClassname={'mobile-flex-end'}
//             //   cb={() => cb(dishData.id)}
//             // />
//             <img src={add} alt="add" onClick={() => cb(dishData.id)}></img>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// };

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  DishCategories: state.app.enums.DishCategory,
  MealDishStatuses: state.app.enums.MealDishStatus,
  MealStatuses: state.app.enums.MealStatus,
  DishStatuses: state.app.enums.DishStatus,
  MealTypes: state.app.enums.MealType,
});
const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(mapStateToProps, mapDispatchToProps)(AddDishToMeal);
